import React from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import { StateProvider } from './src/store/store';

import LaPontaise2 from './static/fonts/LaPontaise-Regular.woff2';
import LaPontaise from './static/fonts/LaPontaise-Regular.woff';
import FoundersGrotesk2 from './static/fonts/FoundersGrotesk.woff2';
import FoundersGrotesk from './static/fonts/FoundersGrotesk.woff';

export const wrapRootElement = ({ element }) => (
  <AnimateSharedLayout>
    <StateProvider>{element}</StateProvider>
    <style>
      {`
          @font-face {
            font-family: 'LaPontaise';
            src: url(${LaPontaise}) format('woff'),
              url(${LaPontaise2}) format('woff2');
            font-weight: normal;
            font-display: fallback;
            font-style: normal;
          }
        
          @font-face {
            font-family: 'FoundersGrotesk';
            src: url(${FoundersGrotesk}) format('woff'),
              url(${FoundersGrotesk2}) format('woff2');
            font-weight: normal;
            font-display: fallback;
            font-style: normal;
          }
          `}
    </style>
  </AnimateSharedLayout>
);
