module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"kalok","accessToken":"MC5YckVucFJFQUFDUUFvdDh3.77-9Ve-_ve-_vQR7Omnvv73vv73vv71hdu-_ve-_ve-_ve-_vRBj77-977-9FO-_vVPvv73vv73vv73vv73vv71iQe-_vQ","schemas":{"homepage":{"Main":{"hero_title":{"type":"StructuredText","config":{"single":"heading1","label":"hero title","placeholder":"Kalok"}},"hero_subtitle":{"type":"StructuredText","config":{"single":"paragraph","label":"Hero subtitle"}},"projects":{"type":"Group","config":{"fields":{"project":{"type":"Link","config":{"select":"document","customtypes":["projects"],"label":"Project"}}},"label":"Projects"}}}},"projects":{"Main":{"uid":{"type":"UID","config":{"label":"Slug"}},"title":{"type":"StructuredText","config":{"single":"heading1","label":"Title"}},"subtitle":{"type":"StructuredText","config":{"single":"heading6","label":"Subtitle"}},"image":{"type":"Image","config":{"constraint":{"width":493,"height":393},"thumbnails":[],"label":"Image"}},"color":{"type":"Color","config":{"label":"color"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4a9773549091c227cd2eb82ccd9c5e3a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
